import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

class Navbar extends Component {
	randomOffset() {
		return {animationDelay: (Math.random() * 0.5 - 0.25) + "s"};
	}
	render () {
		const activeClassName = "active";
		return (
			<nav className="navbar navbar-expand-lg navbar-dark bg-dark py-4">
				<div className="container">
					<Link className="navbar-brand h1 brand" to="/">
						<span className="brand brand_letter" style={this.randomOffset()}>C</span>
						<span className="brand brand_letter" style={this.randomOffset()}>R</span>
						<span className="brand brand_letter" style={this.randomOffset()}>A</span>
						<span className="brand brand_letter" style={this.randomOffset()}>I</span>
						<span className="brand brand_letter" style={this.randomOffset()}>G</span>
						&nbsp;
						<span className="brand brand_letter" style={this.randomOffset()}>H</span>
						<span className="brand brand_letter" style={this.randomOffset()}>O</span>
						<span className="brand brand_letter" style={this.randomOffset()}>R</span>
						<span className="brand brand_letter" style={this.randomOffset()}>W</span>
						<span className="brand brand_letter" style={this.randomOffset()}>O</span>
						<span className="brand brand_letter" style={this.randomOffset()}>O</span>
						<span className="brand brand_letter" style={this.randomOffset()}>D</span>
					</Link>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarNav">
						<div className="navbar-nav ms-auto">
							<NavLink className={({isActive}) => ("nav-link nav-item mx-3 " + (isActive ? activeClassName : ""))} to="/">Home</NavLink>
							<NavLink className={({isActive}) => ("nav-link nav-item mx-3 " + (isActive ? activeClassName : ""))} to="/games">Games</NavLink>
							<NavLink className={({isActive}) => ("nav-link nav-item mx-3 " + (isActive ? activeClassName : ""))} to="/demos">Demos</NavLink>
							<NavLink className={({isActive}) => ("nav-link nav-item mx-3 " + (isActive ? activeClassName : ""))} to="/about">About</NavLink>
						</div>
					</div>
				</div>
			</nav>
		);
	}
}

export default Navbar;