import React, { Component } from 'react';
import ListPage from './ListPage';

class Demos extends Component {
	render () {
		return (
			<ListPage title="Demos" list="demos" />
		);
	}
}

export default Demos;