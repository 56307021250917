import React, { Component } from 'react';
import ImageBreeze from '../img/breeze.png';
import ImageDG2 from '../img/dg2.png';
import ImageEC from '../img/ec.png';
import BadgeAppStore from '../img/badge_appstore.png';
import BadgeGooglePlay from '../img/badge_googleplay.png';
import DownloadIcon from '../svg/download.svg';
import PlayIcon from '../svg/play-fill.svg';
import GithubLogo from '../svg/github.svg';

class Home extends Component {
	render () {
		return (
			<div className="container">
				<div className="row mt-4">
					<div className="col-12 col-sm-4 card-wrapper">
						<div className="card text-center border-0">
							<img className="card-img-top mx-auto" src={ImageBreeze} alt="Breeze" style={{width: "50%", borderRadius: "25%"}} />
							<div className="card-body">
								<h4 className="card-title">Breeze</h4>
								<p className="card-text">A cross-platform mobile game written in C with a custom game engine and graphics rendering system.</p>
								<p className="row justify-content-center">
									<a className="col-12 col-lg-6 mb-1" href="https://apps.apple.com/us/app/breeze-for-iphone/id1384227914" style={{maxWidth: "75%"}}>
    	        			            <img className="img-fluid img-badge" alt="Download on the App Store" src={BadgeAppStore} />
				                    </a>
									<a className="col-12 col-lg-6 mb-1" href="https://play.google.com/store/apps/details?id=com.craighorwood.breeze" style={{maxWidth: "75%"}}>
										<img className="img-fluid img-badge" alt="Get it on Google Play" src={BadgeGooglePlay} />
									</a>
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-4 card-wrapper">
						<div className="card text-center border-0">
							<img className="card-img-top mx-auto" src={ImageDG2} alt="Diamond Gun 2" style={{width: "50%", borderRadius: "25%"}} />
							<div className="card-body">
								<h4 className="card-title">Diamond Gun 2</h4>
								<p className="card-text">A several-hour-long exploration-focused platform game written in Java.</p>
								<p className="row justify-content-center">
									<span className="col-12 col-xl-6">
										<a className="btn btn-dark w-100 mb-1" href="/downloads/DiamondGun2.zip">
											<span className="text-white me-2">Download</span>
											<img src={DownloadIcon} alt="Download" />
										</a>
									</span>
									<span className="col-12 col-xl-6">
										<a className="btn btn-dark w-100 mb-1" href="https://github.com/CraigHorwood/Diamond-Gun-2">
											<span className="text-white me-2">Source</span>
											<img src={GithubLogo} height="16px" width="16px" alt="GitHub" />
										</a>
									</span>
								</p>
							</div>
						</div>
					</div>
					<div className="col-12 col-sm-4 card-wrapper">
						<div className="card text-center border-0">
							<img className="card-img-top mx-auto" src={ImageEC} alt="Elliptic curve demo" style={{width: "50%", borderRadius: "25%"}} />
							<div className="card-body">
								<h4 className="card-title">Elliptic curve demo</h4>
								<p className="card-text">A demonstration of the use of elliptic curves in secure information exchange, written in pure JavaScript.</p>
								<p className="row justify-content-center">
									<span className="col-12 col-lg-6">
										<a className="btn btn-dark w-100" href="/demos/elliptic">
											<span className="text-white me-2">View</span>
											<img src={PlayIcon} alt="View" />
										</a>
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;