import React, { Component } from 'react';
import Helmet from 'react-helmet';
import axios from 'axios';
import DownloadIcon from '../svg/download.svg';
import PlayIcon from '../svg/play-fill.svg';
import GithubLogo from '../svg/github.svg';

class ListPage extends Component {
	state = {
		games: []
	}
	componentDidMount() {
		axios.get("/list.php?list=" + this.props.list)
		.then(result => {
			this.setState({
				games: result.data
			})
		}).catch(error => {
			console.log(error);
		});
	}
	render() {
		const { games } = this.state;
		const renderGames = games.length > 0 ? (
            games.map(game => {
                return (
                    <div className="row" key={game.id}>
                        <div className="card col-xs-1 col-md-8 mb-3">
                            <div className="card-body">
                                <div className="card-title font-weight-bold">{game.name}</div>
                                <div className="card-subtitle text-muted">{game.release}</div>
                                <div className="card-text">{game.description}</div>
                                <div className="row gx-0 mt-2">
                                    {
                                        game.downloadLink ?
                                        (
                                            <span className="col-6 col-sm-4 pe-1">
                                                <a className="btn btn-dark w-100" href={game.downloadLink}>
                                                    <span className="text-white me-2">Download</span>
                                                    <img src={DownloadIcon} alt="Download" />
                                                </a>
                                            </span>
                                        ) : (game.playLink ?
                                            (
                                                <span className="col-6 col-sm-4 pe-1">
                                                    <a className="btn btn-dark w-100" href={game.playLink}>
                                                        <span className="text-white me-2">{this.props.list === "demos" ? "View" : "Play"}</span>
                                                        <img src={PlayIcon} alt={this.props.list === "demos" ? "View" : "Play"} />
                                                    </a>
                                                </span>
                                            ) : (
                                                <span className="dropdown col-6 col-sm-4 pe-1">
                                                    <button className="btn btn-dark dropdown-toggle w-100" type="button" id={"dropdownBtn" + game.id} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <span className="text-white me-2">Download</span>
                                                        <img src={DownloadIcon} alt="Download" />
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby={"dropdownBtn" + game.id} style={{width: "100%"}}>
                                                        <li><a className="dropdown-item" href={game.appLinkIos}>App Store</a></li>
                                                        <li><a className="dropdown-item" href={game.appLinkAndroid}>Google Play</a></li>
                                                    </ul>
                                                </span>
                                            )
                                        )
                                    }
                                    {
                                        game.sourceLink ?
                                        (
                                            <span className="col-6 col-sm-4 ps-1">
                                                <a className="btn btn-dark w-100" href={game.sourceLink}>
                                                    <span className="text-white me-2">Source</span>
                                                    <img src={GithubLogo} height="16px" width="16px" alt="GitHub" />
                                                </a>
                                            </span>
                                        ) : (null)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })
        ) : (
            <div className="w-100 text-center">Loading...</div>
        );
		return (
			<React.Fragment>
				<Helmet>
					<title>{this.props.title} - Craig Horwood</title>
				</Helmet>
				<div className="container">
					<h3 className="mb-4 pt-4">{this.props.title}</h3>
					{renderGames}
				</div>
			</React.Fragment>
		);
	}
}

export default ListPage;