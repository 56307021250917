import React, { Component } from 'react';
import ListPage from './ListPage';

class Games extends Component {
	render () {
		return (
			<ListPage title="Games" list="games" />
		);
	}
}

export default Games;