import React, { Component } from 'react';
import Helmet from 'react-helmet';
import PortraitImage from '../img/portrait.png';

class About extends Component {
	render () {
		return (
			<React.Fragment>
				<Helmet>
					<title>About - Craig Horwood</title>
				</Helmet>
				<div className="container">
					<h3 className="mb-4 pt-4">About</h3>
					<div className="row">
						<div className="col">
							<img className="img-fluid col-12 col-sm-6 col-lg-4 float-end ms-2" src={PortraitImage} />
							<p>
								My name is Craig Horwood, and I've been a computer guy for as long as I can remember. From my early days making simple Flash games in a language that no longer exists, to my modern professional life designing custom encrypted file formats, my approach to software has always been to take as much joy in the journey of creating it as in the destination of delivering it.
							</p>
							<p>
								Most of what you'll find on this website is a showcase of my personal projects, such as video games I created in high school and demonstrations of whatever field of programming or mathematics I'm currently interested in. I've also made sure that the structure of the website itself is distinctly my own and demonstrates my proclivity for going that little bit further with making software, such as in the shimmering of the letters of my name at the top of the page, or the <a href="background.js">procedurally-generated vector art</a> in the background of this very page. Refresh to see another one!
							</p>
							<p>
								I'm fluent in all the major programming languages, ranging from more obtuse close-to-the-hardware staples like C/C++, to the prototyper's dream, Python. I've published software for PC platforms, mobile platforms, and both the front and back ends of the web. Many of these projects, as well as their source code, can be accessed through the links at the top of the page.
							</p>
							<p>
								I currently live in Melbourne, and have recently obtained a bachelor's degree in mathematics from Monash University, following a period of full-time work and part-time study during Melbourne's initial lockdowns. When I'm not working, I enjoy live music, a heady brew, and seeing new places. I'm also a fan of high quality garments and sneakers, as well as an avid guitarist, keyboardist, and music producer.
							</p>
							<p>
								<strong>GitHub:</strong> <a href="https://github.com/CraigHorwood">CraigHorwood</a>
							</p>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default About;