import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './pages/Home';
import Games from './pages/Games';
import Demos from './pages/Demos';
import About from './pages/About';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Navbar />
        <div id="content" className="flex-grow-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/games" element={<Games />} />
            <Route path="/demos" element={<Demos />} />
            <Route path="/about" element={<About />} />
          </Routes>
          <footer className="text-center">
            <small className="text-muted">Copyright &copy; Craig Horwood 2014-2022</small>
          </footer>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
